<template>
  <button
    v-if="!href"
    type="button"
    :class="{
      button: true,
      tinted,
      yellow: color === 'yellow',
      green: color === 'green',
      blue: color === 'blue',
      red: color === 'red',
      pending: pending,
      disabled: disabled,
    }"
  >
    <div style="flex: 1; text-align: center">{{ text }}</div>
  </button>
  <a
    v-else
    type="button"
    :href="href"
    target="_blank"
    :class="{
      tinted,
      yellow: color === 'yellow',
      green: color === 'green',
      blue: color === 'blue',
      red: color === 'red',
      disabled: disabled,
      pending: pending,
    }"
  >
    <div style="flex: 1; text-align: center">{{ text }}</div>
  </a>
</template>

<script lang="ts" setup>
import { computed } from "vue";

type Color = "yellow" | "green" | "blue" | "red";
declare var TINT_COLOR: string;

defineProps<{
  href?: string;
  text: string;
  color: Color;
  pending?: boolean;
  disabled?: boolean;
}>();

const tinted = computed(() => TINT_COLOR !== "#0069FF");
</script>
