import axios from "axios";

import { components as OpenAPI } from "@/autogen/openapi";
import URLS from "@/autogen/urls";
import { Newsletter } from "@/types/newsletter";

export type SubscriptionState =
  | OpenAPI["schemas"]["SubscriberType"]
  | "not_subscribed"
  | "premium"
  | "pending_validation"
  | "pending_subscription"
  | "invalid_email"
  | "invalid_email__regex"
  | "invalid_subscription"
  | "errored_email"
  | "pending_email_address_confirmation"
  | "awaiting_email_address_confirmation";

export type SubscribeFormVariant =
  | "subscribe_page"
  | "archive_page"
  | "paywalled_archive_page";

// This should ideally match what's in `emails/middleware/utm.py`.
const RELEVANT_COOKIES = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

const decomposeCookieString = (
  cookieString: string
): { [key: string]: string } => {
  return Object.fromEntries(
    cookieString
      .split("; ")
      .map((x) => x.split(/=(.*)$/, 2).map(decodeURIComponent))
  );
};

const filterObjectByKeys = (
  object: { [key: string]: string },
  keys: string[]
) => {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => keys.includes(key))
  );
};

const fetchRelevantCookies = (): { [key: string]: string } => {
  return filterObjectByKeys(
    decomposeCookieString(document.cookie),
    RELEVANT_COOKIES
  );
};

export type BasicSubscriber = {
  id: string;
  email_address: string;
  type: SubscriptionState;
};

export const subscribe = async (
  emailAddress: string,
  metadata: { [key: string]: string },
  newsletter: Newsletter,
  referring_subscriber_id: string | null
): Promise<
  | { error: string; subscriber?: BasicSubscriber }
  | { subscriber: BasicSubscriber }
> => {
  const referralInformation = referring_subscriber_id
    ? { referring_subscriber_id: referring_subscriber_id }
    : {};

  const payload = {
    ...referralInformation,
    ...fetchRelevantCookies(),
    ...Object.fromEntries(new URLSearchParams(location.search)),
    metadata,
    tags: new URLSearchParams(document.location.search).getAll("tag"),
    email_address: emailAddress,
    newsletter_id: newsletter.id,
  };

  try {
    const response = await axios.post(URLS["create-subscriber"](), payload);
    const subscriber = {
      id: response.data.subscriber.id as string,
      email_address: response.data.subscriber.email_address as string,
      type: response.data.subscriber
        .type as OpenAPI["schemas"]["Subscriber"]["type"],
    };

    // Handle analytics callbacks. This could probably be better abstracted,
    // but it's not causing any angst at the moment.
    if (window.plausible) {
      window.plausible("Subscribe");
    } else if (window.fathom && newsletter.fathom_subscribe_code) {
      window.fathom.trackGoal(newsletter.fathom_subscribe_code, 0);
    }

    return { subscriber };
  } catch (e: any) {
    return {
      error: e.response?.data?.error,
      subscriber: e.response?.data?.subscriber,
    };
  }
};

export const mutableUrl = (
  newsletter: Newsletter,
  subscriber: { id: string } | undefined,
  route: string
): string | null => {
  if (!subscriber) {
    return null;
  }
  if (newsletter.domain === "") {
    return `/${newsletter.username}/subscribers/${subscriber.id}${route}`;
  }

  return `/subscribers/${subscriber.id}${route}`;
};

// Given: `https://buttondown.com/archive/foo-bar-baz`
// Return: `foo-bar-baz`
export const extractSlug = (url: string): string => {
  if (url.endsWith("/")) {
    return url.toString().split("/").reverse()[1];
  }
  return url.toString().split("/").reverse()[0];
};
